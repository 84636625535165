var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notification_block"},[_c('div',{staticClass:"notif_inner"},[_c('div',{staticClass:"head_row"},[_c('div',{staticClass:"service_title"},[_vm._v(_vm._s(_vm.$t("Notifications")))]),_c('button',{staticClass:"notif_close icon_close",on:{"click":_vm.closeNotifications}})]),_c('div',{staticClass:"notif_list"},[_c('ul',_vm._l((_vm.notifications),function(notif,index){return _c('li',{key:index,class:{ unread: !notif.read_at },on:{"click":function($event){!notif.data.read_at ? _vm.setAsRead(notif) : false}}},[_c('div',{staticClass:"notif_head"},[_c('div',{staticClass:"notif_title"},[_vm._v(_vm._s(notif.data.title))]),_c('span',{staticClass:"notif_status",class:[
                {
                  check_notif: notif.data.status === 'success',
                  icon_checked: notif.data.status === 'success',
                  reject_notif: notif.data.status === 'fail',
                  icon_close: notif.data.status === 'fail',
                  processing: notif.data.status === 'info',
                  icon_arrowStroke: notif.data.status === 'info',
                } ]})]),_c('div',{staticClass:"notif_description"},[_vm._v(_vm._s(notif.data.message))]),_c('div',{staticClass:"notif_date"},[_vm._v(" "+_vm._s(_vm.getTime(notif.updated_at))+" "),_c('span',[_vm._v(_vm._s(_vm.getDate(notif.updated_at)))])])])}),0)]),_c('div',{staticClass:"mark_read_block"},[(_vm.notifications.length)?_c('span',{on:{"click":_vm.setAsReadAll}},[_vm._v(" Mark as read "),_c('img',{attrs:{"src":require("../../assets/images/MarkAllread.svg"),"alt":""}})]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }