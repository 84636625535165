var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left_inner"},[_c('div',{staticClass:"info_head"},[_c('Logo'),_c('LngSwitcher',{attrs:{"lngBlock":"profile_lg"}})],1),_c('div',{staticClass:"personal_info"},[_c('div',{staticClass:"image_row"},[_c('div',{staticClass:"image_block"},[_c('img',{attrs:{"src":_vm.getImage,"alt":"","title":""}})]),_c('button',{staticClass:"icon_notification notif_btn notif_active",class:{ no_notif: _vm.hasUnread },on:{"click":_vm.openNotifications}})]),_c('div',{staticClass:"name_block"},[_vm._v(" "+_vm._s(_vm.getFullName)+" ")]),_c('router-link',{staticClass:"custom_a",attrs:{"custom":"","to":"/profile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('a',{on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("Personal page")))])]}}])})],1),_c('div',{staticClass:"info_content"},[_c('ul',_vm._l((_vm.menuItems),function(item,index){return _c('li',{key:index},[_c('router-link',{class:[
            { selected: _vm.$route.name === item.name },

            ("icon_" + (item.icon) + (_vm.$route.name === item.name ? 'Active' : '')) ],attrs:{"to":item.url,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
          var href = ref.href;
return [_c('a',{staticClass:"custom_a",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t(("" + (item.title))))+" ")])]}}],null,true)})],1)}),0)]),_c('div',{staticClass:"info_bottom"},[_c('div',{staticClass:"bottom_row"},[_c('router-link',{attrs:{"custom":"","to":"/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
return [_c('span',{staticClass:"back_main custom_a",on:{"click":navigate}},[_vm._v(" Fnet "+_vm._s(_vm.$t("Main page")))])]}}])}),_c('a',{staticClass:"log_out icon_logOut custom_a",on:{"click":_vm.logOut}},[_vm._v(_vm._s(_vm.$t("Logout")))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }